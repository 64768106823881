/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7545cd0b-4ce6-4535-bd72-ba0cbcf36b6c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_FgS89Kkyg",
    "aws_user_pools_web_client_id": "5eivtannmlum568143u2no7m1i",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://b3ckgpunkjf2xbtd3nfwctzn5u.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
